<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
    <path
      d="M2.47253 0.714844C1.81677 0.714844 1.18788 0.975341 0.724186 1.43903C0.260498 1.90272 0 2.53162 0 3.18737V4.42364H17.3077V3.18737C17.3077 2.53162 17.0472 1.90272 16.5835 1.43903C16.1198 0.975341 15.4909 0.714844 14.8352 0.714844H2.47253ZM0 10.605V5.6599H17.3077V10.605C17.3077 11.2607 17.0472 11.8896 16.5835 12.3533C16.1198 12.817 15.4909 13.0775 14.8352 13.0775H2.47253C1.81677 13.0775 1.18788 12.817 0.724186 12.3533C0.260498 11.8896 0 11.2607 0 10.605ZM11.7445 9.36869C11.5806 9.36869 11.4233 9.43381 11.3074 9.54974C11.1915 9.66566 11.1264 9.82288 11.1264 9.98682C11.1264 10.1508 11.1915 10.308 11.3074 10.4239C11.4233 10.5398 11.5806 10.605 11.7445 10.605H14.217C14.381 10.605 14.5382 10.5398 14.6541 10.4239C14.77 10.308 14.8352 10.1508 14.8352 9.98682C14.8352 9.82288 14.77 9.66566 14.6541 9.54974C14.5382 9.43381 14.381 9.36869 14.217 9.36869H11.7445Z"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'RedWalletIcon',
  props: {
    color: {
      type: String,
      default: 'FF0000',
    },
  },
}
</script>

<style>

</style>
