<template>
  <div class="biz-container" @scroll="isBottom" id="list-purchase">
    <b-modal id="modal-response" hide-footer header-class="border-0" v-model="modalVisible" centered>
      <template>
        <div class="w-full d-flex flex-column text-center px-5 pb-5">
          <span class="h4 mb-4" style="color:var(--biz-brand-button)">Pastikan anda telah menerima pesanan anda</span>
          <button class="btn text-light corner mb-2 action-button" @click="onChangeStateOrder('COMPLETED')" style="background:var(--biz-brand-button)">Sudah Diterima</button>
          <button class="btn action-button" @click="modalVisible = false" style="border: solid 1px var(--biz-brand-button); color:var(--biz-brand-button)">Belum Diterima</button>
        </div>
      </template>
    </b-modal>
    <h2 class="mb-5">{{$t('menu.purchase.transaction')}}</h2>
    <div class="d-flex col pr-0 justify-content-between mb-3">
      <div class="d-flex container-button-type gap-12 justify-content-between align-items-center mb-3">
        <div v-for="({ title, key }) in listTab" :key="key">
          <div v-if="key === activeKey" class="btn active flex ml-1 w-auto" @click="changeType(key)">{{$t(`menu.purchase.tabs.${title}`)}}</div>
          <div v-else class="btn inactive flex ml-1 w-auto" @click="changeType(key)">{{$t(`menu.purchase.tabs.${title}`) }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex pr-0 justify-content-center align-items-center flex-column pt-5"
      v-if="!loading && data.length === 0">
      <div v-if="activeKey === 0" class="mt-3 ml-2 h4 align-items-center d-flex flex-column">
        <div class="d-flex align-items-center mb-4">
          <EmptyIcon />
        </div>
        <span style="color: var(--biz-brand-button)" class="ml-2 desc-empty-state">Anda belum memiliki transaksi. Yuk, mulai belanja di
          Kino Siap!</span>
        <div class="card-footer border-top-0 mt-1">
          <button @click="goToHome" class="btn w-100 button-empty-state">Belanja
            Sekarang</button>
        </div>
      </div>
      <div v-else class="w-35 d-flex align-items-center flex-column pb-5">
        <div class="d-flex align-items-center">
          <EmptyIcon />
        </div>
        <span style="color: var(--biz-brand-button)" class="ml-2 h4 mt-3 desc-empty-filter">Tidak ada transaksi yang sesuai filter</span>
      </div>
    </div>
    <div v-else id="wrap" class="col pr-0"
      v-for="({ order_number, po_number, firstItem, theRestItem, status, created_at, newTotal, id, title }, index) in data"
      :key="index">
      <div class="card col-12 d-flex justify-content-center">
        <div class="card-header d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="h5 mr-2" style="color:black">{{ order_number || po_number }}</div>
            <div class="h6" style="color:#929292">{{ created_at }}</div>
          </div>
          <div class="btn status text-white tag-status" :style="{ background: bgColor[status] }">
            {{$t(`menu.purchase.tabs.${title}`)}}
          </div>
        </div>
        <div class="card-body d-flex justify-content-between align-items-end">
          <div class="d-flex">
            <div v-if="firstItem && firstItem.image_url" class="mr-3">
              <img :src="firstItem && firstItem.image_url" loading="lazy" style="max-width: 100%; width: 78px" />
            </div>
            <div class="customBox">
              <span>{{ firstItem && firstItem.title }}</span>
              <span>{{ `${firstItem && firstItem.qty} ${firstItem && firstItem.uom}` }}</span>
              <span v-if="theRestItem">{{ `+${theRestItem} Produk lainya` }}</span>
            </div>
          </div>
          <div>
            Total Pembayaran : <span class="h5" style="color: #F5831F;">{{ newTotal }}</span>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <span  v-if="status === 'SHIPPING'" style="color: var(--biz-brand-button); border: 1px solid var(--biz-brand-button)" class="btn action-button"
            @click.stop="openModal(id)">Pesanan Diterima</span>
          <button v-if="status === 'DELIVERED'" disabled style="color: #ABABAB; border: 1px solid #ABABAB"
            class="btn disabled action-button" @click.stop="() => undefined">
            <span>
              <i class="fe fe-clock"></i>
            </span>
            <span>
              Menunggu verifikasi
            </span>
          </button>
          <span style="color: white; border: 1px solid var(--biz-brand-button); background-color: var(--biz-brand-button);" class="btn ml-3 w-auto action-button"
            @click.stop="showDetail(id)">Detail Transaksi</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import RedWalletIcon from '@/components/Icons/RedWallet.vue'
import EmptyIcon from '@/components/Icons/Empty.vue'

export default {
  name: 'component-purchase-index',
  components: {
    RedWalletIcon,
    EmptyIcon,
  },
  data: function () {
    return {
      bgColor: {
        SHIPPING: '#F5831F',
        COMPLETED: 'var(--biz-brand-button)',
        CANCELED: '#FF0000',
        REJECTED: '#FF0000',
        VERIFIED: '#929292',
        CREATED: '#929292',
        ACCEPTED: '#F5831F',
        CANCELREQUESTED: '#929292',
        SUBMITTED: '#929292',
        DELIVERED: '#F5831F',
      },
      listTab: [
        {
          title: 'all',
          value: ['ALL'],
          state: ['ALL'],
          shipping_states: [],
          key: 0,
        },
        {
          title: 'waiting_confirmation',
          value: ['WAITING_CONFIRMATION'],
          state: ['VERIFIED', 'CREATED', 'SUBMITTED', 'CANCELREQUESTED'],
          shipping_states: ['CREATED'],
          key: 1,
        },
        {
          title: 'on_process',
          value: ['ON_PROCESS'],
          state: ['ACCEPTED'],
          shipping_states: ['CREATED'],
          key: 2,
        },
        {
          title: 'shipping',
          value: ['ON_PROCESS'],
          state: ['ACCEPTED'],
          shipping_states: ['SHIPPED','DELIVERED','BUYER_ACCEPTED'],
          key: 3,
        },
        {
          title: 'completed',
          value: ['COMPLETED'],
          state: ['COMPLETED'],
          shipping_states: ['BUYER_ACCEPTED'],
          key: 4,
        },
        {
          title: 'canceled',
          value: ['CANCELED'],
          state: ['CANCELED', 'REJECTED'],
          shipping_states: ['CREATED'],
          key: 5,
        },
      ],
      data: [],
      page: 1,
      loading: false,
      activeKey: 0,
      modalVisible: false,
      confirmedStatusById: '',
      limit: 20,
      total: 0,
    }
  },
  computed: {
    ...mapState(['purchase', 'apps',]),
  },
  methods: {
    changeType(key) {
      this.activeKey = key
      this.page = 1
      this.limit = 20
      const getValueByKey = this.listTab.find(item => item.key === key)
      this.fetchPurchase(getValueByKey?.value, false, getValueByKey?.shipping_states)
    },
    openModal(id) {
      this.confirmedStatusById = id
      this.modalVisible = true
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    showDetail(param) {
      this.$router.push({ path: `/loyalty-redeem/purchase/order/${param}` })
    },
    gotToWaitingPayment() {
      this.$router.push({ path: '/purchase/waiting-payment' })
    },
    fetchPurchase(status, moreFetch, shipping_states = []) {
      this.loading = true
      this.$store.dispatch('purchase/GETALLPURCHASEORDER', {
        page: this.page,
        limit: this.limit,
        channel_id: this.$store.state.app.redeem_channel_id,
        status,
        shipping_states: shipping_states,
      })
        .then(({ data, total_record, limit }) => {
          const newData = (data || [])?.map(({ created_at, ...rest }) => ({
            ...rest,
            created_at: moment(created_at).format('DD MMMM YYYY'),
            firstItem: rest.items[0],
            theRestItem: rest.items && rest.items.length - 1 || '',
            newTotal: Intl.NumberFormat('en-US').format(rest.total_value - (rest?.payment_data?.credit_limit_usage || 0)),
            title: this.listTab.find(item => item.state.includes(rest.status) && item.shipping_states.includes(rest.shipping_state))?.title,
          }))
          const totalRestData = total_record - (data || []).length
          this.data = moreFetch ? [...this.data, ...newData] : newData
          this.loading = false
          this.page = this.page + 1
          this.total = total_record
        })
        .catch((err) => {
          this.loading = false
        })
    },
    isBottom() {
      const el = document.querySelectorAll("#wrap")
      const navbarHeight = 151;
      const offsetElement = 5
      const lastEl = el[el.length - offsetElement]
      const bounds = lastEl.getBoundingClientRect().y
      const alreadyGetAllData = this.data.length >= this.total
      if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
        const getValueByKey = this.listTab.find(({ key }) => key === this.activeKey)
        this.fetchPurchase(getValueByKey?.value, true, getValueByKey?.shipping_states)
      }
    },
    onChangeStateOrder(stateOrder) {
      this.$store.dispatch('purchase/ACCEPTEDORDERBYUSER', {
        order_id: this.confirmedStatusById,
      })
        .then((data) => {
          this.modalVisible = false
          this.confirmedStatusById = ''
          this.page = 1
          const getValueByKey = this.listTab.find(({ key }) => key === 0)
          this.fetchPurchase(getValueByKey?.value, false, getValueByKey?.shipping_states)
        })
        .catch((err) => {
          this.loading = false
        })
    }
  },
  mounted() {
    const getValueByKey = this.listTab.find(({ key }) => key === 0)
    this.fetchPurchase(getValueByKey?.value, false, getValueByKey?.shipping_states)
    window.addEventListener("scroll", this.isBottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.isBottom);
  },
}
</script>

<style>
.active {
  background: #E8F6FE;
  border: 1px solid var(--biz-brand-button) !important;
  border-radius: 12px;
  color: var(--biz-brand-button);
}

.inactive {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  color: #929292;
}

.customBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.status:hover {
  cursor: auto !important;
}

.thumbnail {
  cursor: pointer;
}

.container-button-type {
  overflow-x: hidden;
  overflow: hidden;;
}

.action-button {
  border-radius: 8px;
  padding: 5px 40px;
}

.status-tag {
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 20px;
}

.desc-empty-state {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.desc-empty-filter {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}
.button-empty-state {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  padding: 10px 60px;
  color: var(--biz-brand-button);
  border: 2.25px solid var(--biz-brand-button);
  border-radius: 6.75px;
}
.tag-status {
  border-radius: 16px;
  padding: 5px 12px;
  font-size: 14px;
}
</style>
